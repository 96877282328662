
import React from "react";

export const OtherClans = () => {
    const clanList = [
        {

        },
        {

        },
        {

        },
        {

        },
        {

        },
        {

        },
        {

        },
        {

        },
        {

        },

    ]
    return (
        <div className="flex flex-col px-4 space-y-[18px] z-[1111] ">
            <span className="text-xl text-white leading-none">Other Clans</span>
            <div className="flex space-x-[10px] bg-white-100 backdrop-blur-[10px] p-[10px] rounded-[20px] w-full overflow-auto">
                {clanList.map((item, itemIndex) => {
                    return (
                        <div className="flex flex-col w-[70px] shrink-0 items-center justify-center space-y-[10px]">
                            <img className="w-[70px] h-[70px]" src="/images/header/clan/clanPic.png"/>
                            <div className="flex flex-col items-center justify-center space-y-2">
                                <span className="text-[10px] text-white leading-none">Wild Clickers</span>
                                <span className="text-[#4383FF] text-[8px] leading-none">120 users</span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}