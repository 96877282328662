import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Clicker } from './pages';
import { Boost } from './pages/boost';
import { Earn } from './pages/earn';
import { Profile } from './pages/profile';
import { Team } from './pages/team';
import { AuthPage } from './pages/auth';
import { Clan } from './pages/clan';
import { Timer } from './pages/timer';
import { Leaderboards } from './pages/leaderboards';
import { PrivacyPolicy } from './pages/privacyPolicy';


export const RoutesFind = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Clicker />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/boost" element={<Boost />} />
        <Route path="/team" element={<Team />} />
        <Route path="/earn" element={<Earn />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/clan" element={<Clan />} />
        <Route path="/leaderboards" element={<Leaderboards />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/timer" element={<Timer />} /> */}
      </Routes>
    </Router>
  );
};
