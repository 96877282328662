import { React, useEffect } from 'react';
import { animate, motion, useInView, useMotionValue, useTransform } from 'framer-motion';

export const AnimatedCounter = ({ startCount = 0, endNum, isRounded = true }) => {
  const count = useMotionValue(startCount);
  const rounded = useTransform(count, (latest) => isRounded ? Math.round(latest) : latest);

  useEffect(() => {
    const controls = animate(count, endNum, { duration: 1 });

    return controls.stop;
  }, [endNum]);

  return <motion.div>{rounded}</motion.div>;
};
