import { buyBoostAbi } from "./contractAbi/buyBoost";
import { nftAbi } from "./contractAbi/nft";
import { frgxAbi } from "./contractAbi/frgx";
import { claimRewardsAbi } from "./contractAbi/claimRewards";
import { claimNftSaleAbi } from "./contractAbi/claimNftSale";


export default {
  buyBoostAddress: "0xc4899f43DFcf6DfC2cefe1D4E50CE1716A08B563",
  nftAddress: "0x0a988D92EdcA11C544cb31261492F267704FdA52",
  frgxAddress: "0xC703dA39aE3B9Db67C207c7Bad8100E1AfdC0F9c",
  claimRewardsAddress: "0x1280024B5D059e6aA8e1BBf41122Fe85745B3b12",
  claimRewardsAddress1: "0xcC476031302Ea289687007780CE2e476fAB1274E",
  claimRewardsAddress2: "0xf0A32bb38a5b3aC1ead7Ab21cc6c7Cec997d5481",
  claimNftAddress: "0xF18D07a9c48Db95b7766190739DA1eDd196639dF",
  claimNftSaleAddress: "0x14A844468030bB141Caf82b165D1b977935755bB",

  allowedChainId: 56,

  nftAbi: nftAbi,
  claimNftSaleAbi: claimNftSaleAbi,
  buyBoostAbi: buyBoostAbi,
  frgxAbi: frgxAbi,
  claimRewardsAbi: claimRewardsAbi,
};
