export const nft_3 = {
  "id": "3",
  "description": "This is a unique NFT Skin for CLIX Game by FRGX Space.",
  "image": "https://frgx.space/clickerNft/img/InvisibleMode.png",
  "name": "Invisible Mode",
  "backendType": 'nft_invisible',
  "attributes": [
    {
      "type": "skin",
      "value": "Invisible Mode",
    },
    {
      "type": "clix",
      "value": "30000",
    },
    {
      "type": "multitap",
      "value": "3",
    },
    {
      "type": "energy restore",
      "value": "3",
    },
    {
      "type": "energy limit",
      "value": "3",
    },
  ]
}