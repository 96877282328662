import React from "react";
import { BackButton } from "../../components";
import { Boosters } from "../../features/clicker/boost/boosters";
import { NftSkins } from "../../features/clicker/boost/nftSkins";
import { Header } from "../../features/clicker/components/Header";
import { ClickerLayout } from "../../layouts/ClickerLayout";
import { useSelector } from "react-redux";
import { getUser } from '../../store/userSlice/selectors';

export const Boost = () => {
  const currentUser = useSelector(getUser);
  
  return (
    <ClickerLayout parentStyle='space-y-8'> 
      <Header className="!h-[225px]">
          <img className="max-w-[70%] z-[1]" src="/clicker/boost/header/rockets.png"/>
          <BackButton />
      </Header >
      <Boosters currentUser={currentUser} />
      {/* <NftSkins /> */}
      <img className="absolute bottom-0 z-[0] w-full" src="/clicker/boost/bottomShadow.png"/>
    </ClickerLayout>
  );
}