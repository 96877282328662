import React, { useEffect, useMemo, useState } from "react"
import { useModal } from "../../../../helpers/hooks/useModal"
import { NftModal } from "../../../../components/modals/NftModal"
import { ActivateModal } from "../../../../components/modals"
import { useRequest } from "../../../../helpers/hooks/useRequest"
import { NftRepository } from "../../../../connectors/repositories/nft"
import { cardList } from "../../../../helpers/cards"
import { skinInfo } from "../../../../helpers/skinConfig"
import { useSelector } from "react-redux"
import { getUser } from "../../../../store/userSlice/selectors"

export const NFTSkins = () => {
    const currentUser = useSelector(getUser);
    const [currentNft, setCurrentNft] = useState(null);
    const [userNftList, setUserNftList] = useState([]);
    const { call, isLoading, data } = useRequest(NftRepository.userNft);
    const { call: callSync, isLoading: isLoadingSync, data: dataSync } = useRequest(NftRepository.syncNft);
    const { openedModal, onOpen, onClose } = useModal();
    const { openedModal: openedModalActive, onOpen: onOpenActive, onClose: onCloseActive } = useModal();

    useEffect(() => {
        if (currentUser.walletId) {
            call().then((result) => {
                setUserNftList(result);
            });
        }
    }, [currentUser])

    console.log(data, dataSync);

    const syncFunc = () => {
        callSync().then((result) => {
            setUserNftList(result);
        })
    }

    useEffect(() => {
        if (currentUser.walletId) {
        const interval = setInterval(() => {
            syncFunc();
        }, 50000);
        return () => clearInterval(interval);
    }
      }, [currentUser]);

    const chooseNft = (nftCount, number) => {
        setCurrentNft({ count: nftCount, nftNumber: number });
        onOpen();
    }

    const connectWalletFunc = () => {
        onClose();
        onOpenActive();
    }

    const syncButtonRender = useMemo(() => {
        if (isLoadingSync) {
            return {
                title: 'Syncing NFT',
                onClick: null,
                disabled: true,
                classNameIcon: 'animate-spin',
            }
        } else {
            return {
                title: 'Sync NFT',
                onClick: () => syncFunc(),
                disabled: false,
                classNameIcon: '',
            }
        }
    }, [dataSync, isLoadingSync])

    return (
        <div className="flex flex-col px-4 space-y-[18px] h-full pb-4 z-[1]">
            <div className="flex items-center space-x-2.5">
                <span className="text-xl text-white leading-none">NFT Skins</span>
                {currentUser.walletId && (
                    <button className={`flex items-center space-x-1.5 px-2.5 py-1 text-white bg-white-100 rounded-[20px]`} disabled={syncButtonRender?.disabled} onClick={syncButtonRender?.onClick}>
                        <span>{syncButtonRender?.title}</span>
                        <img className={`w-5 h-5 ${syncButtonRender?.classNameIcon}`} src="/icons/syncIcon.svg" alt="" />
                    </button>
                )}
            </div>
          
            <div className="min-h-[340px] items-center justify-center flex flex-col rounded-[20px] p-2.5 bg-white-100 h-full space-y-2.5 backdrop-blur-small">
                <div className="grid gap-2 grid-cols-3 ">
                {!!userNftList && !!userNftList[0] && userNftList[0]?.split(',')?.map((item, itemIndex) => {
                    if (!!Number(item)) {
                        const image = skinInfo(cardList[itemIndex]?.backendType)?.button;
                        const shadow = skinInfo(cardList[itemIndex]?.backendType)?.gradient;
                        const style = {
                            backgroundImage: `url(${shadow})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                          }
                        return (
                            <button style={style} onClick={() => {syncFunc();chooseNft(item, itemIndex)}} className="relative flex items-center justify-center bg-white-100 w-[101px] h-[101px] rounded-[10px] " key={itemIndex}>
                                <span className="absolute top-1 right-1 text-white bg-lightBlack rounded-full flex items-center justify-center w-6 h-6 z-2">{Number(item)}</span>
                                <img className="h-[75%] w-[75%]" src={image} />
                            </button>
                        )
                    }
                    return (
                        <div className="items-center justify-center bg-white-100 w-[101px] h-[101px] rounded-[10px] ">
                        </div>
                    )
                })}
                </div>
            </div>
            <NftModal connectWalletFunc={() => connectWalletFunc()} syncFunc={() => syncFunc()} currentNft={currentNft} nftList={userNftList} openedModal={openedModal} handleCloseModal={onClose}/>
            <ActivateModal openedModal={openedModalActive} handleCloseModal={onCloseActive} />
        </div>
    )
}