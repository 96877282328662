import { RoutesFind } from "./RoutesFind";
import { Web3ReactProvider } from '@web3-react/core';
import { metaMask, metaMaskHooks, walletConnectV2Hooks, walletConnectV2 } from './connectors/wallets';
import { ManageProvider } from './layouts/ManageProvider';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import { Provider as ReduxProvider } from 'react-redux';
import { getOrCreateStore } from './store';

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
];

function App({ initialReduxState }) {
  const store = getOrCreateStore(initialReduxState);
  return (
    <Web3ReactProvider connectors={connectors}>
      <ReduxProvider store={store}>
        <ManageProvider>
          <RoutesFind />
          <ToastContainer />
        </ManageProvider>
      </ReduxProvider>
    </Web3ReactProvider>
  );
}

export default App;
