import React, { useEffect, useState } from "react";
import { BackButton } from "../../components";
import { Header } from "../../features/clicker/components/Header";
import { UsersList } from "../../features/clicker/leaderboards/UsersList";
import { ClickerLayout } from "../../layouts/ClickerLayout";
import { useSelector } from "react-redux";
import { getUser } from '../../store/userSlice/selectors';
import { LeaderboardRepository } from "../../connectors/repositories/leaderboard";


export const Leaderboards = () => {
  const currentUser = useSelector(getUser);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [currentTab, setCurrentTab] = useState('Main');

  const callCurrentData = async () => {
    let result = null;
    if (currentTab === 'Nft') {
      setIsLoading(true);
      try {
        result = await LeaderboardRepository.getNftLeaderboard({ page: 1, countItemsPerPage: 100 });
      } catch (e) {
        console.log(e);
      }
    } else if (currentTab === 'Main') {
      try {
        result = await LeaderboardRepository.getClixLeaderboard({ page: 1, countItemsPerPage: 100 });
      } catch (e) {
        console.log(e);
      }
    } else if (currentTab === 'Leaders') {
      try {
        result = await LeaderboardRepository.getLeadersLeaderboard({ page: 1, countItemsPerPage: 100 });
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    }
    setData(result);
  }

  useEffect(() => {
    callCurrentData();
  }, [currentTab]);


  const leadersList = [
    {
      title: 'Main',
      key: 'Main',
      available: true, 
    },
    {
      title: 'NFT',
      key: 'Nft',
      available: true, 
    },
    {
      title: 'Leaders',
      key: 'Leaders',
      available: false, 
    },
  ]

  return (
    <ClickerLayout parentStyle='space-y-3 '>  
      <Header className="min-h-[345px] !justify-between pb-4 ">
            <div className="flex flex-col flex flex-col items-center justify-center z-[111] pt-[22px] mb-[18px]">
              <img className="h-[158px]" src="/images/leaderBoards/cup.png"/>
              <span className="text-4xl text-white font-semibold">Leaderboard</span>
            </div>
            <div className="flex justify-between p-2 space-x-2 bg-black-300 backdrop-blur-medium h-[60px] w-full max-w-[343px] z-[111]  rounded-2xl">
              {leadersList.map((item, itemIndex) => {
                  const isActive = currentTab === item?.key;
                  return (
                    <button onClick={item?.available ? () => setCurrentTab(item?.key) : () => {}} className={`rounded-xl ${isActive ? 'bg-[#2A73FF]' : 'bg-white-100'} h-[44px] w-full`} key={itemIndex}>
                      <span className="text-white">{item?.available ? item?.title : 'soon'}</span>
                    </button>
                  )
                })}
            </div>
          <BackButton />
      </Header>
      <UsersList leaderboardType={currentTab} currentUser={currentUser} data={data}/>
      <img className="absolute bottom-0 z-[1] w-full" src="/clicker/boost/bottomShadow.png"/>  
    </ClickerLayout>  
  );
}