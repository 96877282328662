import { useWeb3React } from '@web3-react/core';
import { AuthRepository } from '../../connectors/repositories/auth';
import { useState } from 'react';

export const useSetWallet = () => {
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { account, provider } = useWeb3React();

  const setUserWallet = async () => {
    const nonce = await AuthRepository.getNonce();
    setIsLoading(true);
    if (nonce) {
      try {
        const resultMessage = await provider?.getSigner(account).signMessage(nonce);
        const resultFinal = await AuthRepository.setNonce({
          signedMessage: resultMessage,
          message: nonce,
          address: account, 
        });

        if (resultFinal) {
          setIsComplete(true);
        }
        
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    isComplete,
    isLoading,
    setUserWallet,
  };
};
