export const nft_1 = {
  "id": "1",
  "description": "This is a unique NFT Skin for CLIX Game by FRGX Space.",
  "image": "https://frgx.space/clickerNft/img/Brony.png",
  "name": "Brony",
  "backendType": 'nft_brony',
  "attributes": [
    {
      "type": "skin",
      "value": "Brony",
    },
    {
      "type": "clix",
      "value": "10000",
    },
    {
      "type": "multitap",
      "value": "1",
    },
    {
      "type": "energy restore",
      "value": "1",
    },
    {
      "type": "energy limit",
      "value": "1",
    },
  ]
}