import {useEffect, useMemo, useState} from 'react';
import { parseCookies } from 'nookies';
import { io } from 'socket.io-client';

export const useSockets = () => {
  const cookies = parseCookies();
  const [connectInfo, setConnectInfo] = useState({ connected: false });
  const url = process.env.REACT_APP_SOCKET_URL;
  const eUrl = new URL(url);

  const socket = useMemo(() => {
    return io(eUrl.origin, {
      autoConnect: false,
      auth: {
        token: cookies?.authTokenClix || null,
      },
      transports: ["websocket", "polling"],
      path: eUrl.pathname
    });
  }, [])

  const createSocketConnect = () => {
    socket.connect();
  }

  useEffect(() => {
    socket.on("connect", () => {
      setConnectInfo(socket);
    });

    socket.on("disconnect", () => {
      setConnectInfo(socket);
    });
  }, [])

  return {
    socket,
    connectInfo,
    createSocketConnect,
  };
};
