import React from "react";
import { BackButton } from "../../components";
import { Header } from "../../features/clicker/components/Header";
import { InviteBonus } from "../../features/clicker/earn/inviteBonus";
import { Onboarding } from "../../features/clicker/earn/onboarding";
import { ClickerLayout } from "../../layouts/ClickerLayout";
import Lottie from 'lottie-react';
import whiteLogoAnimation from '../../helpers/animation/whiteLogo.json';


export const Earn = () => {
  return (
    <ClickerLayout parentStyle='flex !items-center !w-full space-y-6'>
      <div className="flex flex-col  w-full relative">
         <div className={`relative flex flex-col items-center justify-center h-full min-h-[200px] rounded-b-[30px] overflow-hidden z-[111]`}>
            <img className="absolute h-full w-full z-[0]" src="/clicker/boost/header/shadow1.png"/>
            <span className="text-4xl text-white font-semibold z-[1] ">Earn more coin</span>
            <img className="absolute bottom-0 left-2 z-[111] w-[84px]" src="/clicker/earn/header/botPinkFrgx.png"/>
            <img className="absolute top-0 right-0 z-[111] w-[106px]" src="/clicker/earn/header/topPinkFrgx.png"/>
            <BackButton />
          </div> 
        {/* <button className="pt-7">
          <span className="text-[#4383FF]">Full guide</span>
        </button> */}
        <img className="absolute w-full top-0 z-[11]" src="/clicker/earn/stars.png"/>
      </div>
      <div className="flex flex-col items-center justify-center space-y-5 pt-28 z-[3]">
        <Lottie animationData={whiteLogoAnimation} loop={true} />
        <span className="text-white text-4xl font-semibold">Coming soon</span>
      </div>
      
      {/* <InviteBonus />
      <Onboarding />
      <img className="absolute bottom-0 z-[0] w-full" src="/clicker/boost/bottomShadow.png"/> */}
      <img className="absolute bottom-0 w-full max-h-[600px]" src="/images/timer/bg.png"/> 
    </ClickerLayout>
  );
}