import React, { useMemo, useEffect } from "react";
import { NFTPremiumSkins } from "../../features/clicker/profile/NFTPremiumSkins";
import { NFTSkins } from "../../features/clicker/profile/NFTSkins";
import { Header } from "../../features/clicker/components/Header";
import { BackButton } from "../../components";
import { ClickerLayout } from "../../layouts/ClickerLayout";
import { destroyCookie } from "nookies";
import { EditProfileModal } from "../../components/modals/EditProfileModal";
import { useModal } from "../../helpers/hooks/useModal";
import { useSelector } from "react-redux";
import { getUser } from "../../store/userSlice/selectors";
import { useWeb3React } from "@web3-react/core";
import { ActivateModal } from "../../components/modals";
import { useSetWallet } from "../../helpers/hooks/useSetWallet";
import { shortenAddress } from "../../helpers/format";
import { ClickerRepository } from "../../connectors/repositories/clicker";
import { useDispatch } from "react-redux";
import { updateUser } from "../../store/userSlice";
import { UserRepository } from "../../connectors/repositories/user";


export const Profile = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(getUser);
    const { account } = useWeb3React();

    const { isComplete, isLoading, setUserWallet } = useSetWallet();
    const { openedModal: openenedModalWallet, onOpen: onOpenWallet, onClose: onCloseWallet } = useModal();


    const logoutAction = () => {
        destroyCookie(undefined, 'authTokenClix');
        window.location.reload();
    }

    const updateUserInfo = async () => {
        try {
          const result = ClickerRepository.getUserInfo();
          if (result) {
            dispatch(updateUser({...currentUser, ...result}));
          }
        } catch (e) {
          console.log(e);
        }
        try {
            const result = UserRepository.getUser();
            if (result) {
              dispatch(updateUser({...currentUser, ...result}));
            }
          } catch (e) {
            console.log(e);
          }
      }

    
      useEffect(() => {
        if (isComplete) {
          updateUserInfo();
        }
      }, [isComplete])

    const { openedModal, onOpen, onClose } = useModal();

    const avatarBg =  {
        backgroundImage: `url(${currentUser?.avatar})`,
        backgroundRepeat: 'round',
        backgroundSize: 'cover',
      };

    const connectButtonRender = useMemo(() => {
        if (!account && !currentUser?.walletId) {
            return {
                title: 'Connect wallet',
                disabled: false, 
                func: () => onOpenWallet(),
            }
        } else { 
            if (isLoading) {
                return {
                    title: 'Loading sync',
                    disabled: true, 
                    func: null,
                }
            } else if (isComplete || currentUser?.walletId) {
                const userAddress = currentUser?.walletId ? currentUser?.walletId : account;
                return {
                    title: `Wallet Synced (${shortenAddress(userAddress)})`,
                    disabled: true, 
                    func: null,
                }
            } else {
                return {
                    title: 'Set user wallet',
                    disabled: false, 
                    func: () => setUserWallet(),
                }
            }
        }
    }, [currentUser, account, isComplete, isLoading])

  return (

        <ClickerLayout parentStyle='space-y-8'> 
          <Header className="!h-[344px]">
                <div className="flex flex-col items-center justify-between px-4 z-[1] space-y-5 w-full h-full pt-[56px] pb-4 w-full">
                    <div style={avatarBg} className="rounded-full w-[120px] h-[120px]" />
                    <div className="flex flex-col items-center space-y-2.5 ">

                        <div className="flex items-center justify-center space-x-1 w-full pb-2.5 border-b border-b-white-300">
                            <span className="text-xl text-white">{currentUser?.nickname}</span>
                        </div>

                        
                        <div className="text-sm text-white opacity-60">Your balance</div>
                        <div className="flex space-x-2.5 items-center !mt-0">
                            <img className="w-10 h-10" src="/clicker/profile/header/pinkFrgx.png"/>
                            <span className="text-[44px] text-white font-semibold">{currentUser?.clicks}</span>
                        </div>
                
                    </div>
                    {/* <button className="flex items-center justify-center bg-white-200 w-full h-[70px] rounded-[20px]">
                        <span className="text-2xl text-white">Claim</span>
                    </button> */}
                    
                </div>
                <img className="absolute z-[0] w-full" src="/clicker/profile/header/shadow.png"/>
                <BackButton />
                <button onClick={() => onOpen()} className="flex items-center justify-center absolute right-4 top-5 bg-white-100 backdrop-blur-large rounded-3xl min-w-[66px] h-[30px] z-[111]">
                    <span className="text-lg text-white">Edit</span>
                </button>
            </Header>
            <div className="px-4 z-[3]">
                <button disabled={connectButtonRender?.disabled} onClick={connectButtonRender?.func} className="flex items-center justify-center space-x-1.5 h-[50px] w-full backdrop-blur-[10px] bg-white-200 rounded-[20px] edit_profile_modal">
                    <img className="pl-1 w-6 h-6" src="/icons/walletIcon.svg"/>
                    <span className="text-white ">{connectButtonRender?.title}</span>
                </button>
            </div>
            <NFTPremiumSkins />
            <NFTSkins />
            <div className="px-4 pb-10 z-[3]">
                <button onClick={() => logoutAction()} className="flex items-center justify-center w-full h-[60px] bg-[#de0a26] rounded-[20px] ">
                    <span className="text-white ">Logout</span>
                </button>
            </div>
            <img className="absolute bottom-0 z-[0] w-full" src="/clicker/boost/bottomShadow.png"/>
            <EditProfileModal currentUser={currentUser} openedModal={openedModal} handleCloseModal={onClose} />
            <ActivateModal  openedModal={openenedModalWallet} handleCloseModal={onCloseWallet} />
        </ClickerLayout>
  );
}