export const TYPE_BOOST = {
  MULTITAP: 'multi_tap',
  ENERGY_LIMIT: 'energy_limit',
  RECHARGING_SPEED: 'energy_restore',
  TAPBOT: 'tap_bot',
}

export const TITLE_BOOST = {
  [TYPE_BOOST?.MULTITAP]: 'Multitap',
  [TYPE_BOOST?.ENERGY_LIMIT]: 'Max energy',
  [TYPE_BOOST?.RECHARGING_SPEED]: 'Energy restore',
  [TYPE_BOOST?.TAPBOT]: 'Tapbot',
}

export const BOOST_ICON = {
  [TYPE_BOOST?.MULTITAP]: '/clicker/boost/boosters/multitap.png',
  [TYPE_BOOST?.ENERGY_LIMIT]: '/clicker/boost/boosters/energy.png',
  [TYPE_BOOST?.RECHARGING_SPEED]: '/clicker/boost/boosters/speed.png',
  [TYPE_BOOST?.TAPBOT]: '/clicker/boost/boosters/bot.png',
}

export const BOOST_PRICES = {
  [TYPE_BOOST?.MULTITAP]: [
    {
      lvl: 1,
      price: 0, 
      currency: '',
    },
    {
      lvl: 2,
      price: 5000, 
      currency: 'Clix',
    },
    {
      lvl: 3,
      price: 15000, 
      currency: 'Clix',
    },
    {
      lvl: 4,
      price: 45000, 
      currency: 'Clix',
    },
    {
      lvl: 5,
      price: 135000, 
      currency: 'Clix',
    },
    {
      lvl: 6,
      price: 405000, 
      currency: 'Clix',
    },
    {
      lvl: 7,
      price: 1215000, 
      currency: 'Clix',
    },
    {
      lvl: 8,
      price: 3645000, 
      currency: 'Clix',
    },
    {
      lvl: 9,
      price: 10935000, 
      currency: 'Clix',
    },
    {
      lvl: 10,
      price: 32805000, 
      currency: 'Clix',
    },
  ],
  [TYPE_BOOST?.ENERGY_LIMIT]: [
    {
      lvl: 1,
      price: 0, 
      currency: '',
    },
    {
      lvl: 2,
      price: 10000, 
      currency: 'Clix',
    },
    {
      lvl: 3,
      price: 30000, 
      currency: 'Clix',
    },
    {
      lvl: 4,
      price: 90000, 
      currency: 'Clix',
    },
    {
      lvl: 5,
      price: 270000, 
      currency: 'Clix',
    },
    {
      lvl: 6,
      price: 810000, 
      currency: 'Clix',
    },
    {
      lvl: 7,
      price: 2430000, 
      currency: 'Clix',
    },
    {
      lvl: 8,
      price: 7290000, 
      currency: 'Clix',
    },
    {
      lvl: 9,
      price: 21870000, 
      currency: 'Clix',
    },
    {
      lvl: 10,
      price: 65610000, 
      currency: 'Clix',
    },
  ],
  [TYPE_BOOST?.RECHARGING_SPEED]: [
    {
      lvl: 1,
      price: 0, 
      currency: '',
    },
    {
      lvl: 2,
      price: 2500, 
      currency: 'Clix',
    },
    {
      lvl: 3,
      price: 7500, 
      currency: 'Clix',
    },
    {
      lvl: 4,
      price: 22500, 
      currency: 'Clix',
    },
    {
      lvl: 5,
      price: 67500, 
      currency: 'Clix',
    },
    {
      lvl: 6,
      price: 202500, 
      currency: 'Clix',
    },
    {
      lvl: 7,
      price: 607500, 
      currency: 'Clix',
    },
    {
      lvl: 8,
      price: 1822500, 
      currency: 'Clix',
    },
    {
      lvl: 9,
      price: 5467500, 
      currency: 'Clix',
    },
    {
      lvl: 10,
      price: 16402500, 
      currency: 'Clix',
    },
  ],
  [TYPE_BOOST?.TAPBOT]: [
    {
      price: {
        currency: 'FRGX',
        value: 20000,
      }, 
      price2: {
        currency: 'Clix',
        value: 100000,
      }, 
    },
  ],
}