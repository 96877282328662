import { nft_1 } from "./cards/1"
import { nft_2 } from "./cards/2"
import { nft_3 } from "./cards/3"
import { nft_4 } from "./cards/4"
import { nft_5 } from "./cards/5"
import { nft_6 } from "./cards/6"
import { nft_7 } from "./cards/7"
import { nft_8 } from "./cards/8"
import { nft_9 } from "./cards/9"

export const cardList = [
  nft_1, nft_2, nft_3, nft_4, nft_5, nft_6, nft_7, nft_8, nft_9 
]


