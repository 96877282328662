export const nft_8 = {
  "id": "8",
  "description": "This is a unique NFT Skin for CLIX Game by FRGX Space.",
  "image": "https://frgx.space/clickerNft/img/3HundredBucks.png",
  "name": "3 Hundred Bucks",
  "backendType": "nft_thb",
  "attributes": [
    {
      "type": "skin",
      "value": "3 Hundred Bucks",
    },
    {
      "type": "clix",
      "value": "250000",
    },
    {
      "type": "multitap",
      "value": "8",
    },
    {
      "type": "energy restore",
      "value": "8",
    },
    {
      "type": "energy limit",
      "value": "8",
    },
  ]
}