import { parseCookies } from 'nookies';
import { useState } from 'react';

export const useCheckAuth = () => {
  const [isDoneCheckAuth, setIsDoneCheckAuth] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const { authTokenClix } = parseCookies();

  const checkAuth = () => {
    if (authTokenClix) {
      setIsAuth(true);
    }
    setIsDoneCheckAuth(true);
  }

  return {
    isAuth,
    checkAuth,
    authTokenClix,
    isDoneCheckAuth,
  }
}