export const skinInfo = (skin) => {
    switch (skin) {
        case 'nft_brony': 
            return {
                gradient: `/clicker/mainBg/${skin}/gradient.png`,
                button: `/clicker/mainButton/${skin}/button.png`,
            }
        case 'nft_predators': 
            return {
                gradient: `/clicker/mainBg/${skin}/gradient.png`,
                button: `/clicker/mainButton/${skin}/button.png`,
            }
        case 'nft_invisible': 
            return {
                gradient: `/clicker/mainBg/${skin}/gradient.png`,
                button: `/clicker/mainButton/${skin}/button.png`,
            }
        case 'nft_pepe': 
            return {
                gradient: `/clicker/mainBg/${skin}/gradient.png`,
                button: `/clicker/mainButton/${skin}/button.png`,
            }
        case 'nft_tomas': 
            return {
                gradient: `/clicker/mainBg/${skin}/gradient.png`,
                button: `/clicker/mainButton/${skin}/button.png`,
            }
            case 'nft_geralt': 
            return {
                gradient: `/clicker/mainBg/${skin}/gradient.png`,
                button: `/clicker/mainButton/${skin}/button.png`,
            }
            case 'nft_thanos': 
            return {
                gradient: `/clicker/mainBg/${skin}/gradient.png`,
                button: `/clicker/mainButton/${skin}/button.png`,
            }
            case 'nft_thb': 
            return {
                gradient: `/clicker/mainBg/${skin}/gradient.png`,
                button: `/clicker/mainButton/${skin}/button.png`,
            }
        case 'nft_ironman': 
            return {
                gradient: `/clicker/mainBg/${skin}/gradient.png`,
                button: `/clicker/mainButton/${skin}/button.png`,
            }
        default: 
        return {
            gradient: `/clicker/mainBg/base/gradient.png`,
            button: `/clicker/mainButton/base/button.png`,
        }
    }
}


