export const nft_5 = {
  "id": "5",
  "description": "This is a unique NFT Skin for CLIX Game by FRGX Space.",
  "image": "https://frgx.space/clickerNft/img/ThomasTrain.png",
  "name": "Thomas Train",
  "backendType": 'nft_tomas',
  "attributes": [
    {
      "type": "skin",
      "value": "Thomas Train",
    },
    {
      "type": "clix",
      "value": "50000",
    },
    {
      "type": "multitap",
      "value": "5",
    },
    {
      "type": "energy restore",
      "value": "5",
    },
    {
      "type": "energy limit",
      "value": "5",
    },
  ]
}