import { requestApi } from "../api";
import { BaseRepository } from "./base";

class Repository extends BaseRepository {
  getUser = (params) => requestApi("post", `${this.path}/me`, params);
  setAvatar = (params) => requestApi("post", `${this.path}/settings/avatar`, params, true);
  updateNickname = (params) => requestApi("post", `${this.path}/settings/space`, params);
}

export const UserRepository = new Repository("/user");
