import React, { useEffect, useState } from "react";
import { Counter } from "../features/clicker/main/Counter";
import { Header } from "../features/clicker/main/Header";
import { Menu } from "../features/clicker/main/Menu";
import { ClickerLayout } from "../layouts/ClickerLayout";
import { destroyCookie, parseCookies } from "nookies";
import { useSockets } from "../helpers/hooks/useSockets";
import { useSelector } from "react-redux";
import { getUser } from '../store/userSlice/selectors';
import { useNavigate } from "react-router-dom";
import { SoundToggle } from "../features/clicker/main/SoundToggle";
import { ClaimWindow } from "../features/clicker/claimWindow";


export const Clicker = () => {
  const navigate = useNavigate();
  const currentUser = useSelector(getUser);
  
  const [click, setClick] = useState({ clixByClick: 1, clixValue: 0 });
  const [energy, setEnergy] = useState({ maxEnergy: 0, currentEnergy: null });

  const cookies = parseCookies();
  const { connectInfo, createSocketConnect, socket } = useSockets();

  useEffect(() => {
    if (cookies.authTokenClix && !connectInfo.connected) {
      createSocketConnect();
    }   
  }, [cookies, connectInfo])


  useEffect(() => {
    if (currentUser) {
      setEnergy({ ...energy, maxEnergy: currentUser?.energyMax?.value})
      setClick({ clixByClick: currentUser?.clixByClick?.value || 0, clixValue: currentUser?.clicks || 0 })
    }
  }, [currentUser])

  useEffect(() => {
    const interval = setInterval(() => {
      socket.emit('getEnergyData:getEnergy', null, (data) => {
        setEnergy({...energy, currentEnergy: Number(data?.currentEnergy)})
      })
    }, 5000);
    return () => clearInterval(interval);
  }, [energy]);

  useEffect(() => {
    if(connectInfo?.connected && socket) {
      socket.emit('getEnergyData:getEnergy', null, (data) => {
        setEnergy({...energy, currentEnergy: Number(data?.currentEnergy)})
      })
    }
  }, [connectInfo.connected, socket])

  const onClick = (e) => {  
    if (energy?.currentEnergy > 2) {    
      socket.emit('clickExecuteAction:clickExecute', [], (data) => {
        setEnergy({...energy, currentEnergy: Number(data?.newEnergy)})
      })
      setClick({...click, clixValue: click?.clixValue + click?.clixByClick })
    }
  }
  
  return (
    <ClickerLayout>
      <div className="w-full h-full px-5 pt-9 pb-10 flex flex-col justify-between flex-1 space-y-8">
        <Header />
        {/* <div className='flex flex-1 flex-col w-full items-center z-10'>
          <Counter energy={energy} setClick={onClick} click={click} />
        </div> */}
        <ClaimWindow />
        <div className="w-full relative ">
          <Menu energyInfo={energy} />
          {/* <SoundToggle className='absolute right-0 top-[-35%]' /> */}
        </div>
      </div>
      <img className="absolute top-0 right-0 z-[1] w-full pt-5" src="/clicker/main/stars.svg"/>
      {/* <img className="absolute bottom-0 right-0 z-[0] w-full" src="/clicker/main/bgShadow/whiteShadow.png"/> */}
    </ClickerLayout>
  );
}