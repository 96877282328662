import React, { useState } from "react";
import { useModal } from "../../../../helpers/hooks/useModal";
import { CreateClanModal } from "../../../../components/modals/CreateClanModal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "../../../../store/userSlice/selectors";

export const Header = () => {
    const currentUser = useSelector(getUser);

    // const [clanStatus] = useState(false);
    const { openedModal, onOpen, onClose } = useModal();

    const avatarBg =  {
        backgroundImage: `url(${currentUser?.avatar})`,
        backgroundRepeat: 'round',
        backgroundSize: 'cover',
      };
    
    return (
        <div className="absolute top-5 right-5 flex space-x-4 z-[10]">
            {/* <button className="flex items-center p-2.5 bg-white-100 w-full h-[70px] clan-block-shadow rounded-[20px] space-x-2.5">
                <img className="w-[50px] h-[50px]" src="/images/header/clan/clanPic.png"/>
                <div className="flex flex-col items-start justify-center ">
                    <span className="text-base text-white">Clans soon</span> */}
                    {/* <span className="text-base text-white">{clanStatus ? 'Сreate or view clans' : 'Wild Clickers'}</span>
                    <span className="text-sm text-white-600">{clanStatus ? 'And fun together' : 'View your team'}</span> */}
                {/* </div>
            </button> */}
            <Link to={'/profile'} className="flex items-center" >
                <div className="rounded-full h-[54px] w-[54px]" style={avatarBg} />
            </Link>
            <CreateClanModal openedModal={openedModal} handleCloseModal={onClose} />
        </div>
    )
}