import React, { useState, useMemo, useEffect } from "react";
import { Modal } from "../../Modal";
import { cardList } from "../../../helpers/cards";
import { useCallTransaction } from "../../../helpers/hooks/useCallTransaction";
import { CONTRACT_NAMES } from "../../../helpers/constants";
import { useGetContract } from "../../../helpers/hooks/useGetContract";
import { callNotification } from "../../../helpers/notification";
import { NftRepository } from "../../../connectors/repositories/nft";
import { parseErrorToUserReadableMessage } from "../../../helpers/format";
import { useWeb3React } from "@web3-react/core";
import { useRequest } from "../../../helpers/hooks/useRequest";
import { useDispatch } from "react-redux"
import { updateUser } from "../../../store/userSlice"
import { ClickerRepository } from "../../../connectors/repositories/clicker"
import { useSelector } from "react-redux";
import { getUser } from "../../../store/userSlice/selectors";
import { skinInfo } from "../../../helpers/skinConfig";
import { useNavigate } from "react-router-dom";

export const NftModal = ({ connectWalletFunc, syncFunc, currentNft, nftList, openedModal, handleCloseModal }) => {
  const currentUser = useSelector(getUser);
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const dispatch = useDispatch();
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);
  const [isDoneOpen, setIsDoneOpen] = useState(false);
  const { onCallTransaction, transactionInfo, clearTransactionInfo } = useCallTransaction()
  const [isLoadingTransaction, setIsLoadingTransactions] = useState(false);
  const { call, isLoading, data } = useRequest(NftRepository.changeNft, [{ newSkin: cardList[currentNft?.nftNumber]?.backendType }]);

  const closeModalFunc = async () => {
    handleCloseModal();
    syncFunc();
  }

  const openNft = async () => {
    if(!isLoadingTransaction) {
      setIsLoadingOpen(true);
      setIsLoadingTransactions(true)
      try {
        const contract = await getContract(CONTRACT_NAMES.SCLIX);
        let gas = null;

        try {
          gas = await contract.estimateGas.lock(currentNft?.nftNumber + 1, 1);
        } catch (e) {
          //
        }

        const result = await contract.lock(currentNft?.nftNumber + 1, 1);

        if (result.hash) {
          try {
            const resultOpen = await NftRepository.openNft({transactionHash: result.hash});

            if (resultOpen) {
              callNotification({ message: resultOpen?.message })
              setIsDoneOpen(true);
              handleCloseModal();
              syncFunc();
            }
          } catch (e) {
            console.log(e);
          } finally {
            setIsLoadingOpen(false);
            clearTransactionInfo();
          }
        }
       

        onCallTransaction(result)
      } catch (e) {
        callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
      }

      setIsLoadingTransactions(false)
    }
  }

  const allNft = !!nftList ? nftList[0]?.split(',')[currentNft?.nftNumber] : 0;
  const lockedNft = !!nftList ? nftList[1]?.split(',')[currentNft?.nftNumber] : 0;
  const availibleNft = allNft - lockedNft;

  const nftButtonInfo = useMemo(() => {
    if (account) {
      if (availibleNft) {
        return {
          title: 'Use NFT',
          disabled: false,
          func: () => openNft(),
          className: '!bg-[#1B68FF]',
        }
      } else {
        return {
          title: 'Buy NFT for use',
          disabled: true,
          func: () => {},
          className: '',
        }
      }
    } else {
      return {
        title: 'Connect wallet',
        disabled: false,
        func: () => connectWalletFunc(),
        className: '!bg-[#1B68FF]',
      }
    }
  }, [account, availibleNft])

  const updateUserInfo = async () => {
    try {
      const result = ClickerRepository.getUserInfo();
      if (result) {
        dispatch(updateUser({...currentUser, ...result}));
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (data) {
      updateUserInfo();
    }
  }, [data])

  const setSkinButton = useMemo(() => {
    if (currentUser.skin === cardList[currentNft?.nftNumber]?.backendType) {
      return {
        title: 'Skin installed',
        disabled: true, 
        func: () => {}, 
        className: '!bg-[#45D842] !opacity-100'
      }
    } else if (lockedNft > 0) {
      return {
        title: 'Set this skin',
        disabled: false, 
        func: () => {
          call().then((result) => {
            updateUserInfo();
            window.location.reload();
          })
        },
        className: '!bg-[#1B68FF]',
      }
    } else {
      return {
        title: 'Use after set skin',
        disabled: true, 
        func: () => {},
        className: '',
      }
    }
  }, [account, availibleNft, currentUser, lockedNft])

  const buttonImage = skinInfo(cardList[currentNft?.nftNumber]?.backendType)?.button;
  const shadowImage = skinInfo(cardList[currentNft?.nftNumber]?.backendType)?.gradient;
  const style = {
      backgroundImage: `url(${shadowImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }

  return (
      <Modal isOpened={openedModal} onClose={closeModalFunc}>
        <div className="relative flex flex-col items-center justify-between pt-10 pb-[30px] px-[10px] clan-modal w-[350px]  backdrop-blur-medium bg-white-100 border-[1px] border-white-100 rounded-[30px]  sm:w-[95%]">
        <button onClick={closeModalFunc} className="absolute top-2.5 right-2.5 p-2.5 flex items-center justify-center ">
            <img className="w-5 h-5" src="/icons/close.svg" alt="" />
          </button>
          <div className="flex flex-col items-center space-y-8 w-full">
            <div style={style} className="flex flex-col items-center justify-center space-y-4 w-[240px] h-[240px] bg-white-200 rounded-[20px]">
              <img className="h-[75%] w-[75%]" src={buttonImage} />
            </div>
            <div className="w-full flex flex-col space-y-2.5">
              <div className="w-full flex space-x-2.5 text-white">
                <div className="flex flex-col items-center justify-center flex-1 bg-white-100 p-2.5 rounded-[16px]">
                  <span className="text-sm text-white-800">Availible nft</span>
                  <span className="font-semibold text-xl">{allNft - lockedNft}</span>
                </div>
                <div className="flex flex-col items-center justify-center flex-1 bg-white-100 p-2.5 rounded-[16px]">
                <span className="text-sm text-white-900">Locked nft</span>
                  <span className="font-semibold text-xl">{lockedNft}</span>
                </div>
              </div>
              <button disabled={nftButtonInfo?.disabled} onClick={() => nftButtonInfo?.func()} className={`flex items-center justify-center h-[60px] w-full backdrop-blur-[10px] bg-white-200 rounded-[20px] ${nftButtonInfo?.className}`}>
                <span className="text-white">{nftButtonInfo?.title}</span>
              </button>
              <button disabled={setSkinButton?.disabled} onClick={() => setSkinButton?.func()} className={`flex items-center justify-center h-[60px] w-full backdrop-blur-[10px] bg-white-200 rounded-[20px] ${setSkinButton?.className}`}>
                <span className="text-white">{setSkinButton?.title}</span>
              </button>
            </div>
            {/* <div className="flex flex-col items-start justify-start w-full space-y-[18px]">
              <span className="text-xl text-white">Other Skins</span>
              <div className="flex space-x-[10px] overflow-auto w-full h-full">
                {clanList.map((item, itemIndex) => {
                  return (
                  <div className="flex flex-col space-y-[10px] w-[70px] overflow-hidden shrink-0">
                    <img className="h-full w-full" src={`/clicker/profile/NFTPremiumSkins/NFT.png`} />
                    <div className="flex flex-col space-y-2 text-center">
                      <span className="text-white text-[10px] leading-[9px]">Predarors</span>
                    </div>
                  </div>
                  )
                })}
              </div>
            </div> */}
          </div>
        </div>
      </Modal>
  );
};
