import React, { useMemo, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useModal } from "../../helpers/hooks/useModal";
import { ActivateModal } from "../modals";
import { useGetContract } from "../../helpers/hooks/useGetContract";
import { CONTRACT_NAMES } from "../../helpers/constants";
import { DEFAULT_GAS_LIMIT } from "../../helpers/constants";
import { toWei, fromWei } from "../../helpers/numbers";
import { useCallTransaction } from "../../helpers/hooks/useCallTransaction";
import { Timer } from "../Timer";


export const ClaimButton = ({ closeClaim = false, endTime = null, type = 'nftBalance', className = '' }) => {
    const [isCompletedTimer, setIsCompletedTimer] = useState(false);
    const { onCallTransaction, transactionInfo } = useCallTransaction()

    const typesList = {
        nftBalance: {
            title: 'Claim TOP-100 starter NFT',
            contract: CONTRACT_NAMES.CLAIMREWARDS,
        },
        team: {
            title: 'Claim TOP-100 Clix balance',
            contract: CONTRACT_NAMES.CLAIMREWARDS1,
        },
        totalClixBalance: {
            title: 'Claim TOP-100 Team',
            contract: CONTRACT_NAMES.CLAIMREWARDS2,
        },
    };

    const [claimInfo, setClaimInfo] = useState({ isClaimed: null, valueToClaim: null });
    const { account } = useWeb3React();
    const { getContract } = useGetContract();
    const { openedModal: openenedModalWallet, onOpen: onOpenWallet, onClose: onCloseWallet } = useModal();

    const callClaimInfo = async () => {
        try {
            const contract = await getContract(typesList[type]?.contract);

            const resultToClaim = await contract.toClaim(account);
            const resultClaimed = await contract.isClaimed(account);
            
            if ((resultToClaim !== null || resultToClaim !== undefined) && (resultClaimed !== null || resultClaimed !== undefined)) {
                setClaimInfo({ isClaimed: resultClaimed, valueToClaim: Number(fromWei(resultToClaim)).toFixed(0) });
            }
        } catch (e) {
            console.log(e);
        }
    }

    const claimRewards = async () => {
        try {
            let result;
            const contract = await getContract(typesList[type]?.contract);

            result = await contract.claim();
            if (result) {
                onCallTransaction(result);
                return result;
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (transactionInfo.isSuccess) {
            setClaimInfo({...setClaimInfo, isClaimed: true });
        }
    }, [transactionInfo])


    const connectButtonRender = useMemo(() => {
        if (!account) {
            return {
                title: 'Connect wallet',
                className: '!bg-main-blue',
                func: () => onOpenWallet(),
            }
        } else if (!!closeClaim) {
            return {
                title: 'Claim closed',
                className: '!bg-white-200 text-white',
                func: null,
            }
        } else if (claimInfo?.isClaimed || !isCompletedTimer) {
            return {
                title: 'Reward claimed',
                className: '!bg-white-200 text-white',
                func: null,
            }
        } else if (claimInfo?.valueToClaim === 0 || !claimInfo?.valueToClaim || claimInfo?.valueToClaim === null) {
            return {
                title: 'Nothing to claim',
                className: '!bg-lightGray',
                func: null,
            }
        } else {
            return {
                title: `Claim ${claimInfo?.valueToClaim} FRGX`,
                className: '!bg-[#45D842]',
                func: () => claimRewards(),
            }
        }
    }, [account, claimInfo, isCompletedTimer, closeClaim])


    useEffect(() => {
        if (account) {
            callClaimInfo();
        }
    }, [account])

    return (
        <>
            <div className="flex flex-col items-start space-y-1.5 bg-white-100 p-2.5 rounded-[20px]">
                <span className="text-white">{typesList[type]?.title}</span>
                <button disabled={connectButtonRender?.disabled || transactionInfo?.isWaiting} onClick={connectButtonRender?.func} className={`z-[5] flex items-center justify-center space-x-1.5 h-[50px] w-full backdrop-blur-[10px] bg-white-200 rounded-[20px] ${className || ''} ${connectButtonRender?.className}`}>
                    {isCompletedTimer || !endTime ? (
                        <span className="text-white ">{connectButtonRender?.title}</span>
                    ) : (
                        <Timer onComplete={() => setIsCompletedTimer(true)} time={endTime} />
                    )}
                </button>
            </div>
            <ActivateModal  openedModal={openenedModalWallet} handleCloseModal={onCloseWallet} />
        </>
    );
}