import React from "react";
import { Modal } from "../../Modal";

export const CreateClanModal = ({ openedModal, handleCloseModal }) => {

  const clanList = [
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
    {

    },
  ]

  return (
    <Modal isOpened={openedModal} onClose={handleCloseModal}>
    <div className="flex flex-col items-center justify-between pt-10 pb-[30px] px-[10px] clan-modal w-[350px]  backdrop-blur-medium bg-white-100 border-[1px] border-white-100 rounded-[30px]  sm:w-[95%]">
      <div className="flex flex-col items-center space-y-8 w-full">
        <button className="flex flex-col items-center justify-center space-y-4 w-[240px] h-[240px] bg-white-200 rounded-[20px]">
          <img src="/icons/clanModal/picChange.svg"/>
          <span className="text-center text-sm text-white-600 ">Upload <br />avatar for your Clan</span>
        </button>
        <div className="flex flex-col items-center justify-center space-y-2">
          <span className="text-white-600 text-sm font-light">Enter name Clan</span>
          <button className="flex items-center space-x-[10px]">
            <span className="text-2xl text-white">Name</span>
            <img src="/icons/clanModal/nameChange.svg"/>
          </button>
        </div>
        <button className="flex items-center justify-center h-[60px] w-full backdrop-blur-[10px] bg-white-200 rounded-[20px]">
          <span className="text-white">Create Clan</span>
        </button>
        <div className="flex flex-col items-start justify-start w-full space-y-[18px]">
          <span className="text-xl text-white">Othr Clans</span>
          <div className="flex space-x-[10px] overflow-auto w-full">
            
          </div>
        </div>

      </div>

    </div>
  </Modal>
  );
};
