import React from "react";

export const Header = ({children, className}) => {
  return (
    <div className={`w-full flex flex-col z-[111] ${className}`}>
      <div className="flex-1 h-full relative flex flex-col items-center justify-center rounded-b-[30px] overflow-hidden">
        {children}
        <img className="absolute top-0" src="/clicker/boost/header/stars.png"/>
        <img className="absolute h-full w-full" src="/clicker/boost/header/shadow1.png"/>
      </div>
    </div> 
  )
}