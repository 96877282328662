import { requestApi } from "../api";
import { BaseRepository } from "./base";

class Repository extends BaseRepository {
  getVerifyCode = (params) => requestApi("post", `${this.path}/code`, params);
  sendVerifyCode = (params) => requestApi("post", `${this.path}/verify`, params);
  getNonce = (params) => requestApi("get", `${this.path}/nonce`, params);
  setNonce = (params) => requestApi("post", `${this.path}/nonce`, params);
  authTelegram = (params) => requestApi("post", `${this.path}/telegram`, params);
}

export const AuthRepository = new Repository("/auth");
