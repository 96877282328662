export const nft_2 = {
  "id": "2",
  "description": "This is a unique NFT Skin for CLIX Game by FRGX Space.",
  "image": "https://frgx.space/clickerNft/img/Predators.png",
  "name": "Predators",
  "backendType": 'nft_predators',
  "attributes": [
    {
      "type": "skin",
      "value": "Predators",
    },
    {
      "type": "clix",
      "value": "20000",
    },
    {
      "type": "multitap",
      "value": "2",
    },
    {
      "type": "energy restore",
      "value": "2",
    },
    {
      "type": "energy limit",
      "value": "2",
    },
  ]
}