import { useWeb3React } from "@web3-react/core";
import { Contract } from "@ethersproject/contracts";
import { CONTRACT_NAMES } from "../constants";
import config from "../config";

export const useGetContract = () => {
  const { account, provider } = useWeb3React();

  const types = {
    [CONTRACT_NAMES.SCLIX]: [
      config.nftAddress,
      config.nftAbi,
    ],
    [CONTRACT_NAMES.BUYBOOST_SALE]: [
      config.buyBoostAddress,
      config.buyBoostAbi,
    ],
    [CONTRACT_NAMES.FRGX]: [
      config.frgxAddress,
      config.frgxAbi,
    ],
    [CONTRACT_NAMES.CLAIMREWARDS]: [
      config.claimRewardsAddress,
      config.claimRewardsAbi,
    ],
    [CONTRACT_NAMES.CLAIMREWARDS1]: [
      config.claimRewardsAddress1,
      config.claimRewardsAbi,
    ],
    [CONTRACT_NAMES.CLAIMREWARDS2]: [
      config.claimRewardsAddress2,
      config.claimRewardsAbi,
    ],
    [CONTRACT_NAMES.CLAIMNFT]: [
      config.claimNftAddress,
      config.nftAbi,
    ],
    [CONTRACT_NAMES.CLAIMNFTSALE]: [
      config.claimNftSaleAddress,
      config.claimNftSaleAbi,
    ],
  };

  const getContract = type => {
    return new Promise(function(resolve, rejected) {
      if (types[type] && provider) {
        const contract = new Contract(
          ...types[type],
          provider?.getSigner(account).connectUnchecked() || provider
        );

        resolve(contract);
      } else {
        rejected("error init contract: " + type);
      }
    });
  };
  return {
    getContract,
  };
};
