export const nft_9 = {
  "id": "9",
  "description": "This is a unique NFT Skin for CLIX Game by FRGX Space.",
  "image": "https://frgx.space/clickerNft/img/IronMan.png",
  "name": "Iron Man",
  "backendType": "nft_ironman",
  "attributes": [
    {
      "type": "skin",
      "value": "Iron Man",
    },
    {
      "type": "clix",
      "value": "750000",
    },
    {
      "type": "multitap",
      "value": "9",
    },
    {
      "type": "energy restore",
      "value": "9",
    },
    {
      "type": "energy limit",
      "value": "9",
    },
  ]
}