import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "../../Modal";
import { BOOST_ICON, BOOST_PRICES, TYPE_BOOST, TITLE_BOOST } from "../../../helpers/boosts";
import { BoostRepository } from "../../../connectors/repositories/boost";
import { useRequest } from "../../../helpers/hooks/useRequest";
import { useWeb3React } from "@web3-react/core";
import { toWei, increaseByPercent } from "../../../helpers/numbers";
import { useGetContract } from "../../../helpers/hooks/useGetContract";
import { CONTRACT_NAMES } from "../../../helpers/constants";
import { DEFAULT_GAS_LIMIT } from "../../../helpers/constants";
import { useCallTransaction } from "../../../helpers/hooks/useCallTransaction";
import { parseErrorToUserReadableMessage } from "../../../helpers/format";
import { callNotification } from "../../../helpers/notification";
import config from "../../../helpers/config";
import { MAX_VALUE } from "../../../helpers/constants";
import { ClickerRepository } from "../../../connectors/repositories/clicker";
import { useDispatch } from 'react-redux';
import { updateUser } from "../../../store/userSlice";

const initialApproveInfo = {
  isCallApprove: false,
  isApproved: false,
  isWaitingApprove: false,
};

const initialBuyStatus = {
  loading: false, 
  complete: false,
}

export const BuyBoostModal = ({ currentUser, boost, openedModal, handleCloseModal }) => {
  const [buyStatus, setBuyStatus] = useState(initialBuyStatus);
  const [isLoadingTransaction, setIsLoadingTransactions] = useState(false);
  const { onCallTransaction, transactionInfo, setTransactionInfo } = useCallTransaction()
  const { getContract } = useGetContract();
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const currentType = boost?.type;
  const nextLevel = boost?.type === TYPE_BOOST?.TAPBOT ? 1 : boost?.lvl;
  const currentPrice = BOOST_PRICES?.[currentType]?.[nextLevel]?.price;
  const isClixCurrency = BOOST_PRICES?.[currentType]?.[nextLevel]?.currency === 'Clix';
  const [approveInfo, setApproveInfo] = useState({ ...initialApproveInfo });

  const { data, isLoading, call } = useRequest(BoostRepository.buyBoostClix, [{ boostType: currentType }]);
  const { data: dataFrgx, isLoading: isLoadingFrgx, call: callFrgx } = useRequest(BoostRepository.buyBoostFrgx);

  const closeModal = () => {
    setBuyStatus(initialBuyStatus);
    handleCloseModal();
  }

  const buyBoostClix = () => {
      if (BOOST_PRICES?.[currentType]?.[nextLevel]?.price <= currentUser?.clicks) {
        setBuyStatus({ ...buyStatus, loading: true });
        call().then((data) => {
          if (data?.statusCode === 200) {
            setBuyStatus({ complete: true, loading: false });
            if (data?.message) {
              callNotification({ message: data?.message})
            }
          }
        });
      }
  }

  const updateUserData = async () => {
    try {
      const result = await ClickerRepository.getUserInfo();
      if (result) {
        dispatch(updateUser({...currentUser, ...result}));
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (buyStatus.complete) {
      updateUserData();
    }
  }, [buyStatus])

  useEffect(() => {
    if (transactionInfo.hash) {
      callFrgx([{ boostType: currentType, transactionHash: transactionInfo.hash }]);
      setTransactionInfo({
        hash: null,
        isWaiting: false,
        isError: false,
        isSuccess: false,
      })
    }
  }, [transactionInfo])

  const buyBoostFRGX = async () => {
    if(!isLoadingTransaction) {
      setIsLoadingTransactions(true)
      try {
        const contract = await getContract(CONTRACT_NAMES.BUYBOOST_SALE);
        let gas = null;
        try {
          gas = await contract.estimateGas.buyBoost(config.frgxAddress, 1, toWei(0.1));
        } catch (e) {
          //
        }

        const result = await contract.buyBoost(config.frgxAddress, 1, toWei(0.1), {
          gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT
        });

        onCallTransaction(result)
      } catch (e) {
        callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
      }

      setIsLoadingTransactions(false)
    }
  }

  const checkApprove = async () => {
    const MIN_BALANCE = parseInt(MAX_VALUE, 16);
    try {
      const contractToken = await getContract(CONTRACT_NAMES.FRGX);
      const approveBalance = await contractToken.allowance(account, config.buyBoostAddress);
  
      const isAllowed = currentPrice ? parseInt(approveBalance) >= parseInt(toWei(currentPrice)) : approveBalance >= MIN_BALANCE;
  
      if (isAllowed) {
        setApproveInfo((prev) => ({ ...prev, isApproved: true }));
      } else {
        setApproveInfo((prev) => ({ ...prev, isApproved: false }));
      }
    } catch (e) {
      setApproveInfo((prev) => ({ ...prev, isApproved: false }));
    }
  };

  const callApprove = async () => {
      setApproveInfo(() => ({ ...initialApproveInfo, isCallApprove: true }));
      try {
        const contractToken = await getContract(CONTRACT_NAMES.FRGX);

        const result = await contractToken.approve(config.buyBoostAddress, MAX_VALUE);
  
        setApproveInfo((prev) => ({ ...prev, isWaitingApprove: true }));
  
        callNotification({ type: 'info', message: 'Transaction was sent. Please wait', autoClose: 10000 });
  
        await result.wait();
  
        setApproveInfo((prev) => ({ ...prev, isApproved: true }));
  
      } catch (e) {
        console.log(e);
        callNotification({ type: 'error', message: 'Transaction rejected by user' });
      } finally {
        setApproveInfo({ ...initialApproveInfo });
      }
  }
  
  useEffect(() => {
    if (account) {
      checkApprove();
    }
  }, [account])

  const onClickFunc = () => {
      if (isClixCurrency) {
        buyBoostClix();
      } else {
        buyBoostFRGX();
      } 
  }

  const [frgxBalance, setFrgxBalance] = useState(null);
  const [isLoadingFrgxBalance, setIsLoadingFrgxBalance] = useState(true);

  const fetchBalanceFrgx = async () => {
    try {
      const contract = await getContract(CONTRACT_NAMES.FRGX);
      const resultBalanceFRGX = await contract?.balanceOf(account);
      const balanceFrgx = parseInt(resultBalanceFRGX) / 1e18;
      setIsLoadingFrgxBalance(false);
      setFrgxBalance(balanceFrgx);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (account) {
      fetchBalanceFrgx();
    }
  }, [account]);

  const disabledButton = BOOST_PRICES?.[currentType]?.[nextLevel]?.currency === 'Clix' ? BOOST_PRICES?.[currentType]?.[nextLevel]?.price > currentUser?.clicks : !account ;
  const isNeedMoreBalance = isClixCurrency ? currentPrice > currentUser.clix : currentPrice > frgxBalance;

  const buyButtonText = useMemo(() => {
    if (buyStatus.loading || isLoadingTransaction || transactionInfo.isWaiting || approveInfo.isWaitingApprove) {
      return 'Loading';
    } else if (isNeedMoreBalance) {
      return 'Insufficient balance';
    } else if (buyStatus.complete) {
      return 'Complete';
    } else {
      return 'Buy boost';
    }
  }, [buyStatus, isLoadingTransaction, transactionInfo, approveInfo, isNeedMoreBalance])
 
  return (
    <Modal isOpened={openedModal} onClose={closeModal}>
        <div className="relative flex flex-col items-center justify-between p-2.5 clan-modal w-[350px]  backdrop-blur-medium bg-white-100 border-[1px] border-white-100 rounded-[30px]  sm:w-[95%]">
          <button onClick={closeModal} className="absolute top-2.5 right-2.5 p-2.5 flex items-center justify-center ">
            <img className="w-5 h-5" src="/icons/close.svg" alt="" />
          </button>
         
          <div className="flex flex-col items-center mb-[24px]">          
            <div className="h-[174px] w-full flex items-center justify-center">
              <img className="max-h-[80%] h-full" src={BOOST_ICON?.[currentType]} alt="" />
            </div>
            
            <div className="flex flex-col items-center w-full text-white space-y-3">
              <span className="text-white text-2xl font-medium">Upgrade {TITLE_BOOST?.[currentType]}</span>
              <div className="flex items-center space-x-0.5">
                <span>{BOOST_PRICES?.[currentType]?.[nextLevel]?.price}</span>
                <span>{BOOST_PRICES?.[currentType]?.[nextLevel]?.currency}</span>
                <span className="text-white-300">x</span>
                <span className="text-white-300">{nextLevel} lvl</span>
              </div>
            </div>
          </div>
          <button disabled={disabledButton} onClick={onClickFunc} className={`w-full ${buyStatus.complete ? 'bg-[#45D842]' : 'register-button-bg'} text-black h-[60px] rounded-[20px]`}>
            <span className="text-white font-light">{buyButtonText}</span>
          </button>
        </div>
      </Modal>
  )
}