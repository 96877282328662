import React from "react";
import { ClickerLayout } from "../../layouts/ClickerLayout";
import { BackButton } from "../../components";

import { OtherClans } from "../../features/clicker/clan/otherClans";
import { YourTeam } from "../../features/clicker/team/yourTeam";
import { ReferalLink } from "../../features/clicker/team/referalLink";

export const Clan = () => {
    const styleBg = {
        backgroundImage: 'url(/images/clan/mainBg.png)',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }

    return (
        <ClickerLayout parentStyle='space-y-8 flex flex-col'> 
            <div style={styleBg} className={`relative flex flex-col items-center justify-end pb-6 !h-[375px] rounded-b-[30px] overflow-hidden z-[111] shrink-0`}>
                <div className="flex flex-col items-center justify-end space-y-2 z-[1]">
                    <span className="text-2xl text-white font-medium leading-none">Wild Clickers</span>
                    <span className="text-sm text-white-600">120 Partners</span>
                </div>
                <BackButton />
                <img className="absolute bottom-0 w-full" src="/images/clan/bottomShadow.png"/>
            </div>
            <ReferalLink />
            <OtherClans />
            <YourTeam />
            <img className="absolute bottom-0 z-[0] w-full" src="/clicker/boost/bottomShadow.png"/>  
        </ClickerLayout>  
    )
}