import React from "react";
import { format, parseISO } from "date-fns";

export const UsersList = ({ leaderboardType = 'Main', data, currentUser }) => {
  const lastUpdateTime = data?.leaderboard ? format(parseISO(data?.leaderboard?.lastUpdate), 'dd/MM HH:mm:ss') : 0;

  return (
    <>
      <span className="text-white py-0 text-center">Your place - {data?.position[0]?.position}</span>
      <div className="flex flex-col w-full px-4 pb-4 z-[111]">
        <div className="flex flex-col bg-white-100 px-[10px] py-3 w-full rounded-[20px] ">
        {data?.leaderboard?.listObject?.items.map((item, itemIndex) => {
            const avatarBg = {
              backgroundImage: `url(${item?.avatar})`,
              backgroundRepeat: 'round',
              backgroundSize: 'cover',
            };
            const isCurrentUser = item?.id === currentUser?.id;
            return (
              <div className={`flex items-center py-1`} key={itemIndex}>
                <div className={`relative flex items-center space-x-[10px] py-2 w-full ${isCurrentUser ? 'bg-white-100 rounded-[16px]' : ''}`}>
                  <span className="text-white font-semibold w-[32px] text-center">{itemIndex + 1}</span>
                  <div className="w-[50px] h-[50px] bg-white rounded-2xl" style={avatarBg} />
                  <div className="flex flex-col items-start">
                    <span className="text-white">{item?.nickname}</span>
                    <div className="flex items-center space-x-1">
                        <img className="w-4 h-4" src="/clicker/boost/boosters/frgxPink.png" />
                        <span className="text-white-600 text-sm font-light">{leaderboardType === 'Nft' ? item?.clixAll : item?.clix}</span>
                    </div>
                  </div>
                  {isCurrentUser && <span className="absolute text-[#60F62C] top-1/2 -translate-y-1/2 right-5 text-sm">You</span>}
                </div>
              </div>
            )
          })}
        </div>
        <span className="text-white-500 py-0 text-center mt-3.5">Last update - {lastUpdateTime}</span>
      </div>
    </>
  )
}