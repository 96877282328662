import React, { useState, useMemo, useEffect } from "react";
import { ClickerLayout } from "../../layouts/ClickerLayout";
import { AuthRepository } from "../../connectors/repositories/auth";
import { callNotification } from '../../helpers/notification';
import { setCookie } from "nookies";
import { useNavigate } from "react-router-dom";
import { useCheckReflink } from "../../helpers/hooks/useCheckReflink";
import { ClaimButton } from "../../components/ClaimButton";

import TelegramLoginButton from 'react-telegram-login';

export const AuthPage = () => {
  const navigate = useNavigate();
  const [emailValue, setEmailValue] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [sendedEmail, setSendedEmail] = useState(false);
  const [sendedCode, setSendedCode] = useState(false);
  const [isLoadingCall, setIsLoadingCall] = useState(false);
  const { uplineData, checkReflink } = useCheckReflink();

  useEffect(() => {
    checkReflink();
  }, [])

  const onAuthTelegram = async (data) => {
    try {
      if (data) {
        const finalData = {
          referralId: uplineData,
          payload: data,
        };

        const result = await AuthRepository.authTelegram(finalData);
        if (result) {
          setCookie(null, 'authTokenClix', result?.data, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
          });
          navigate("/");
        }
      }
    } catch (e) {
      console.log(e);
      callNotification({ message: e?.data?.errors[0].messages[0] });
    }
  };

  const sendEmail = async (emailValue) => {
    setIsLoadingCall(true);
    try {
      const params = !!uplineData ? { email: emailValue, referralId: Number(uplineData) } : { email: emailValue };
      const result = await AuthRepository.getVerifyCode(params);
      if (result.statusCode === 200) {
        callNotification({ message: result?.message });
        setSendedEmail(true);
      }
    } catch (e) {
      callNotification({ type: 'error', message: 'email should not be empty' });
    } finally {
      setIsLoadingCall(false);
    }
   
  }

  const sendVerifyCode = async (emailValue, codeValue) => {
    setIsLoadingCall(true);
    setSendedCode(true);
    try {
      const result = await AuthRepository.sendVerifyCode({ email: emailValue, code: codeValue });
      if (result.statusCode === 200) {
        callNotification({ message: result?.message });
        setCookie(null, 'authTokenClix', result?.data, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
        });

        navigate("/");
      }
    } catch (e) {
      if (e.message) {
        callNotification({ type: 'error', message: 'code should not be empty' });
        
      }
      console.log(e);
      setSendedCode(false)
    } finally {
      setIsLoadingCall(false);
    }
   
  }

  const content = useMemo(() => {
    if (sendedEmail) {
      return {
        title: 'Verify Code',
        input: {
          type: 'text',
          valueInput: codeValue,
          onChangeInput: (e) => setCodeValue(e.target.value),
          text: 'Your code',
          placeholder: 'Enter your code'
        },
        button: {
          text: 'Play',
          disabled: !!sendedCode,
          func: () => sendVerifyCode(emailValue, codeValue),
        },
      }
    } else {
      return {
        title: 'Login',
        input: {
          type: 'email',
          valueInput: emailValue,
          onChangeInput: (e) => setEmailValue(e.target.value),
          text: 'Your email',
          placeholder: 'Enter your email'
        },
        button: {
          text: 'Login',
          disabled: !!sendedEmail,
          func: () => sendEmail(emailValue),
        },
      }
    }
  }, [emailValue, codeValue, sendedEmail, sendedCode])

  return (
    <ClickerLayout parentStyle='flex items-center justify-center h-full px-[17px] space-y-8'> 
      <img className="absolute top-0 w-full" src="/clicker/auth/star.png"/>
      <img className="max-w-[200px] z-[1]" src="/clicker/auth/logo_with_text.png" alt="" />
      <div className="flex flex-col items-center justify-center w-full text-white space-y-8 z-[1]">
        <span className="text-4xl font-semibold">{content?.title}</span>
        <div className="relative flex flex-col items-center w-full space-y-[18px]">
          {/* <div className="text-white flex flex-col items-center justify-center h-[225px] w-full rounded-[16px] bg-lightBlack p-5 text-center justify-center absolute top-[35%] left-1/2 -translate-x-1/2 -translate-y-1/2">
            <img className="w-10 h-10 mb-2.5" src="/icons/warning.svg" alt="" />
            Quick maintenance break <br /> thanks for hanging tight, back soon!
          </div>
          <span className="font-light text-center">{content?.input?.text}</span> */}



          {/* <div className="flex flex-col w-full space-y-8">
            <input placeholder={content?.input?.placeholder} className="text-center placeholder:text-white-300 text-white focus:outline-none h-[60px] rounded-[20px] bg-white-100" type={content?.input?.type} onChange={(e) => content?.input?.onChangeInput(e)} value={content?.input.valueInput} />
            <button disabled={true || content?.button?.disabled} className="register-button-bg text-black h-[60px] rounded-[20px]" onClick={() => content?.button?.func()}>
              <span className="text-white font-light">{isLoadingCall ? 'loading' : content?.button?.text}</span>
            </button>
            {sendedEmail && <button onClick={() => setSendedEmail(false)}> Back to email </button>}
          </div> */}
          {/* <hr className="text-white-500 w-full" /> */}
          <TelegramLoginButton botName={process.env.REACT_APP_LOGIN_TGBOT} dataOnauth={onAuthTelegram} buttonSize="large" usePic={false} />
        </div>
      </div>
      <img className="absolute bottom-0 z-[0] w-full max-h-[500px]" src="/clicker/auth/shadow.png"/>
    </ClickerLayout>
  )
}