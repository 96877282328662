
import React, { useEffect, useState } from "react";
import { copy } from "../../../../helpers/copy";
import { useSelector } from "react-redux";
import { getUser } from "../../../../store/userSlice/selectors";
import { PartnersRepository } from "../../../../connectors/repositories/partners";
import { callNotification } from "../../../../helpers/notification";


export const ReferalLink = ({ userId }) => {
    const currentUser = useSelector(getUser);
    const [claimValue, setClaimValue] = useState(currentUser?.partnersCount - currentUser?.partnersBonusClaimCount);
    const refLink = window.location.host + '/?ref=' + userId;
    const textToTelegram = [
        `Hello! Currently enjoying CLIX, a clicker game that lets you earn real crypto. Give it a shot! ${refLink}`,
        `Hi! Just wanted to share about CLIX, a cool clicker game offering real crypto rewards for clicks. Check it out! ${refLink}`,
        `Hey! Playing CLIX, a clicker game where you can earn real crypto. It's worth giving a try! ${refLink}`
    ];

    const getRandomText = () => {
        let number = Math.floor(Math.random() * 3);
        return textToTelegram[number];
      }

    useEffect(() => {
        setClaimValue(currentUser?.partnersCount - currentUser?.partnersBonusClaimCount);
    }, [currentUser])

    const claimBenefits = async () => {
        if (claimValue) {
            try {
                const result = await PartnersRepository.getClaimBenefits();
                if (result) {
                    callNotification({ message: 'Bonus received' })
                    setClaimValue(0);
                }
            } catch (e) {
                if (e?.data?.message) {
                    callNotification({ message: e?.data?.message })
                }
                console.log(e);
            }
        } 
    }
    
    return (
        <div className="flex flex-col px-4 space-y-[18px] ">
            <div className="flex justify-between items-center space-x-5">
                <span className="text-xl text-white leading-none">Your Referal Link</span>
                <a 
                    href={`https://telegram.me/share/url?url=${getRandomText()}`} 
                    target="_blank"
                    className="flex items-center justify-center bg-[#1B68FF] rounded-[20px] px-2.5 py-1.5"
                >
                    <span className="text-white ">Send invite</span>
                </a>
            </div>
            
            <div className="flex flex-col space-y-3.5">
                <div className="flex items-center justify-between bg-white-100 backdrop-blur-small rounded-[20px] h-[60px] pl-5 pr-2.5 py-2.5">
                    <span className="text-white-300">{refLink}</span>
                    <button onClick={() => copy(refLink)} className="flex items-center jsutify-center bg-[#1B68FF] p-2 rounded-[10px]">
                        <img src="/clicker/team/copyIcon.svg"/>
                    </button>
                </div>
                <button 
                    onClick={claimBenefits}
                    disabled={claimValue <= 0}
                    className={`flex items-center justify-center w-full h-[60px] rounded-[20px] ${claimValue > 0 ? ' bg-[#1B68FF]' : 'bg-white-200'} `}
                >
                    <span className="text-white ">{claimValue > 0 ? `Claim ${claimValue * 1000} CLIX` : 'Invite new partners and claim clix'}</span>
                </button>
            </div>
            {/* <button>
                <span className="text-[#4383FF]">What will I get? </span>
            </button> */}
        </div>
    )
}