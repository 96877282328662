import React, { useMemo } from "react";
import { Link } from 'react-router-dom';
import { AnimatedCounter } from "../../../../components";
import ReactPlaceholder from 'react-placeholder';
import {RectShape} from 'react-placeholder/lib/placeholders';
import "react-placeholder/lib/reactPlaceholder.css";

const PowerPlaceholder = (
    <div className='relative w-full h-[44px]'>
        <div className="absolute top-1/2 -translate-y-1/2 left-5 flex items-center justify-center space-x-2.5">
            <img className=" flex-shrink-0 w-[20px] h-[34px]" src="/clicker/main/flashIcon.png"/>
            <span className="text-white-400 font-semibold">Loading Energy</span>
        </div>
        <RectShape color='rgba(255, 255, 255, 0.2)' style={{width: '100%', height: '100%', borderRadius: 40}}/>
    </div>
  );

export const Menu = ({ energyInfo, isWidth}) => {

    const menuList = [
        {
            title: 'Team',
            href: 'team',
        },
        {
            title: 'Earn',
            href: 'earn',
        },
        {
            title: 'Boost',
            href: 'boost',
        },
        {
            title: 'Top-100',
            href: 'leaderboards',
        },
      ]

      const finalWidth = (energyInfo?.currentEnergy/energyInfo?.maxEnergy * 100);

      const currentEnergy = !!energyInfo?.currentEnergy ? energyInfo?.currentEnergy.toFixed(0) : 0;

      const menuContainerWidth = !!document ? document.getElementById("menu_container")?.offsetWidth : 0;

    return(
        <div id='menu_container' className="flex flex-col w-full space-y-2.5 pb-4 z-[10]">
            <div className="flex space-x-2.5 w-full z-[11]">
                {menuList?.map((item, itemIndex) => {
                    return (
                        <Link to={`/${item?.href}`} className="flex flex-col items-center justify-center bg-white-200 w-full h-[70px] rounded-[20px] space-y-[3px]" key={itemIndex}>
                            <img className="w-[36px] h-[36px]" src={`/clicker/main/menu/${item?.href}.png`}/>
                            <span className="text-xs text-white">{item?.title}</span>
                        </Link>
                );
                })}
            </div>
            {/* <ReactPlaceholder showLoadingAnimation ready={!!energyInfo?.currentEnergy} customPlaceholder={PowerPlaceholder}>
                <div className="relative w-full">
                    <div style={{ width: `${finalWidth}%` }} className="relative overflow-hidden flex items-center space-x-2.5 w-full h-[44px] rounded-white rounded-[40px] py-[5px]">
                        <div style={{ width: `${menuContainerWidth}px` }} className="absolute energy_bg w-full h-full"></div>
                    </div>
                    <div className="absolute top-1/2 -translate-y-1/2 left-0 px-5 flex items-center space-x-2.5 w-full h-full"> 
                        <img className="flex-shrink-0 w-[20px] h-[34px]" src="/clicker/main/flashIcon.png"/>
                        <div className="flex items-center space-x-[5px] whitespace-nowrap">
                            <span className="text-xl text-white font-medium"><AnimatedCounter isRounded={false} startCount={currentEnergy} endNum={currentEnergy} /></span>
                            <span className="text-xl text-white font-medium opacity-30">/ {energyInfo?.maxEnergy}</span>
                        </div>
                    </div>
                </div>
            </ReactPlaceholder> */}
        </div>
    )
}