import React, { useMemo, useState } from "react";
import { BuyBoostModal } from "../../../../components/modals";
import { useModal } from "../../../../helpers/hooks/useModal";
import { BOOST_ICON, TYPE_BOOST, BOOST_PRICES } from "../../../../helpers/boosts";

export const Boosters = ({ currentUser }) => {
    const { openedModal, onOpen, onClose } = useModal();
    const [choosedBoost, setChoosedBoost] = useState(null);

    const boostersList = useMemo(() => [
        {
            type: TYPE_BOOST?.MULTITAP,
            title: 'Multitap',
            lvl: currentUser?.clixByClick?.level,
            available: true, 
        },
        {
            type: TYPE_BOOST?.ENERGY_LIMIT,
            title: 'Energy Limit',
            lvl: currentUser?.energyMax?.level,
            available: true, 
        },
        {
            type: TYPE_BOOST?.RECHARGING_SPEED,
            title: 'Recharging Speed',
            lvl: currentUser?.energyRecoverPerSec?.level,
            available: true, 
        },
        {
            type: TYPE_BOOST?.TAPBOT,
            title: 'Tap bot',
            lvl: 1, 
            available: false, 
        }
    ], [currentUser]);

    const chooseBoost = (boostItem) => {
        onOpen();
        setChoosedBoost(boostItem);
    }

    return (
        <div className="flex flex-col px-4 space-y-[18px]  z-[1]">
            <span className="text-xl text-white ">Boosters</span>
            <div className="flex flex-col rounded-[20px] p-2.5 bg-white-100 h-full space-y-2.5 backdrop-blur-small">
            {boostersList?.map((item, itemIndex) => {
                    const nextLVL = item?.lvl;
                    const currentPrice = BOOST_PRICES?.[item?.type]?.[nextLVL]?.price;
                    const currency = BOOST_PRICES?.[item?.type]?.[nextLVL]?.currency;

                    return (
                        <button onClick={item?.available ? () => chooseBoost(item) : () => {}} className="flex justify-between items-center w-full" key={itemIndex}>
                            <div className="flex space-x-2.5">
                                <div className="flex items-center justify-center bg-white-100 w-[70px] h-[70px] rounded-[10px] ">
                                    <img className="h-[48px]" src={BOOST_ICON?.[item?.type]} />
                                </div>
                                <div className="flex flex-col items-start justify-center">
                                    <span className="text-white text-lg">{item?.title} (Lvl {item?.lvl})</span>
                                    {item?.available ? (
                                        <div className="flex items-center space-x-1">
                                            {/* <img className="w-4 h-4 " src="/clicker/boost/boosters/frgxPink.png" /> */}
                                            {/* <span className="text-sm text-white font-light">{currentPrice}</span> */}
                                            {/* <img src="/clicker/boost/boosters/x.svg" /> */}
                                            <span className="text-sm text-white-500">Upgrade for {currentPrice} {currency}</span>
                                        </div>
                                    ) : (
                                        <div className="text-white-500 ">
                                            <span>Coming soon</span>
                                        </div>
                                    )}
                                    
                                </div>
                            </div>
                            <img className="pr-2" src="/clicker/boost/boosters/arrow.svg" />
                        </button>
                    )
                })}
            </div>
            <BuyBoostModal currentUser={currentUser} boost={choosedBoost} openedModal={openedModal} handleCloseModal={onClose} />
        </div>
    )
}