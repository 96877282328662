import React from "react";
import { BackButton } from "../../components";
import { Header } from "../../features/clicker/components/Header";
import { ReferalLink } from "../../features/clicker/team/referalLink";
import { YourTeam } from "../../features/clicker/team/yourTeam";
import { ClickerLayout } from "../../layouts/ClickerLayout";
import { useSelector } from "react-redux";
import { getUser } from '../../store/userSlice/selectors';


export const Team = () => {
  const currentUser = useSelector(getUser);

  return (
    <ClickerLayout parentStyle='space-y-8'>  
      <Header className="!min-h-[200px]">
          <span className="text-sm text-white opacity-60">Your Team</span>
          <div className="flex items-center justify-center space-x-2.5 z-[1]">
            <span className="text-4xl text-white font-semibold">{currentUser?.partnersCount} Players</span>
            <img className="h-[34px]" src="/clicker/team/header/heart.png"/>
          </div>
          <BackButton />
      </Header>
      <ReferalLink userId={currentUser?.id} />
      <YourTeam />
      <img className="absolute bottom-0 z-[0] w-full" src="/clicker/boost/bottomShadow.png"/>  
    </ClickerLayout>  
  );
}