
import { React, useMemo } from 'react';
import { AnimatedCounter } from '../../../../components';
import { motion } from 'framer-motion';
import ReactPlaceholder from 'react-placeholder';
import {RoundShape} from 'react-placeholder/lib/placeholders';
import "react-placeholder/lib/reactPlaceholder.css";
import { useSelector } from 'react-redux';
import { getUser } from '../../../../store/userSlice/selectors';

import useSound from 'use-sound';
import clickSound from '../../../../sounds/onClick-mainButton.mp3';
import { skinInfo } from '../../../../helpers/skinConfig';
import { callNotification } from '../../../../helpers/notification';

const ButtonPlaceholder = (
    <RoundShape color='rgba(255, 255, 255, 0.2)' style={{ width: 250, height: 250 }} />
)

export const Counter = ({ energy, setClick, click }) => {
    const currentUser = useSelector(getUser);
    const [play] = useSound(clickSound);
    const clickFunc = async (e) => {
        if (e.isTrusted) {
            if (currentUser.clickSound === 'on') {
                play();
            }
            setClick(e);
        } else {
            callNotification({ message: 'Clicks made by a bot are not counted' })
        }
    }

    return (
        <div className="flex flex-col flex-1 items-center space-y-8">
            <div className="flex items-center justify-between space-x-2.5 ">
                <img className="w-[54px] h-[54px]" src={skinInfo(currentUser?.skin).button} />
                <span className="text-[44px] text-white font-semibold "> 
                    <AnimatedCounter endNum={click?.clixValue} />
                </span>
            </div>
            <div className="flex flex-1 items-center justify-center">
            <ReactPlaceholder showLoadingAnimation ready={!!energy?.currentEnergy} customPlaceholder={ButtonPlaceholder}>
                    <motion.button
                        whileTap={{ scale: 0.92 }}
                        onClick={clickFunc} className={`relative flex flex-col items-center justify-center hover:!opacity-100`}>
                        <img className="w-[250px] h-[250px]" src={skinInfo(currentUser?.skin).button} />
                        <img className="z-[2] absolute bottom-[-24%] left-1/2 -translate-x-1/2" src="/clicker/main/shadowUnderClicker.png"/>
                    </motion.button>
            </ReactPlaceholder>
            </div>
        </div>
    )
}