import React, { useMemo, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useModal } from "../../helpers/hooks/useModal";
import { ActivateModal } from "../modals";
import { useGetContract } from "../../helpers/hooks/useGetContract";
import { CONTRACT_NAMES } from "../../helpers/constants";
import { DEFAULT_GAS_LIMIT } from "../../helpers/constants";
import { toWei, fromWei } from "../../helpers/numbers";
import { callNotification } from "../../helpers/notification";
import { parseErrorToUserReadableMessage } from "../../helpers/format";
import { useCheckNft } from "../../helpers/hooks/useCheckNft";
import { useCallTransaction } from "../../helpers/hooks/useCallTransaction";
import { Timer } from "../Timer";

export const ClaimNftButton = ({ endTime = null, className = '' }) => {
    const [isCompletedTimer, setIsCompletedTimer] = useState(false);
    const [isLoadingTransaction, setIsLoadingTransactions] = useState(false);
    const [claimInfo, setClaimInfo] = useState({ isClaimed: null, valueToClaim: null });
    const { account } = useWeb3React();
    const { getContract } = useGetContract();
    const { openedModal: openenedModalWallet, onOpen: onOpenWallet, onClose: onCloseWallet } = useModal();

    const { onCallTransaction, transactionInfo } = useCallTransaction()

    const { checkNft, nftList } = useCheckNft();

    useEffect(() => {
        if (account) {
            checkNft();
        }
    }, [account])

    useEffect(() => {
        if (transactionInfo.isSuccess) {
            checkNft();
        }
    }, [transactionInfo])

    const buyCard = async () => {
        if(!isLoadingTransaction) {
          setIsLoadingTransactions(true)
          try {
            const contract = await getContract(CONTRACT_NAMES.CLAIMNFTSALE);
            let gas = null;
            try {
              gas = await contract.estimateGas.buy('0x0000000000000000000000000000000000000000', 1, {
                value: toWei(0),
              });
            } catch (e) {
              //
            }
    
            const result = await contract.buy('0x0000000000000000000000000000000000000000', 1, {
              value: toWei(0),
              gasLimit: DEFAULT_GAS_LIMIT
            });

            onCallTransaction(result);
          } catch (e) {
            callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
          }
    
          setIsLoadingTransactions(false)
        }
      }


    const connectButtonRender = useMemo(() => {
        if (!account) {
            return {
                title: 'Connect wallet',
                className: '!bg-main-blue',
                func: () => onOpenWallet(),
            }
        }  else if (!!nftList[0] || !isCompletedTimer) {
            return {
                title: 'Nft claimed',
                className: '!bg-white-200 text-white',
                func: null,
            }
        } else {
            return {
                title: 'Claim Clix beta nft',
                className: '!bg-[#45D842]',
                func: () => buyCard(),
            }
        }
    }, [account, nftList, isCompletedTimer])

    return (
        <>
            <div className="flex flex-col items-start space-y-1.5 bg-white-100 p-2.5 rounded-[20px]">
                <span className="text-white">Claim Clix beta NFT</span>
                <button disabled={connectButtonRender?.disabled || transactionInfo?.isWaiting || transactionInfo?.isSuccess} onClick={connectButtonRender?.func} className={`z-[5] flex items-center justify-center space-x-1.5 h-[50px] w-full backdrop-blur-[10px] bg-white-200 rounded-[20px] ${className || ''} ${connectButtonRender?.className}`}>
                    {isCompletedTimer || !endTime ? (
                        <span className="text-white ">{connectButtonRender?.title}</span>
                    ) : (
                        <Timer onComplete={() => setIsCompletedTimer(true)} time={endTime} />
                    )}
                </button>
            </div>
            <ActivateModal  openedModal={openenedModalWallet} handleCloseModal={onCloseWallet} />
        </>
    );
}