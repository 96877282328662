import React, { useEffect, useMemo } from "react";
import { useCheckAuth } from "../../helpers/hooks/useCheckAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { ClickerRepository } from "../../connectors/repositories/clicker";
import { UserRepository } from "../../connectors/repositories/user";
import { useRequest } from "../../helpers/hooks/useRequest";
import { updateUser } from '../../store/userSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { getUser } from "../../store/userSlice/selectors";
import { skinInfo } from "../../helpers/skinConfig";


export const ClickerLayout = ({ children, parentStyle = '' }) => {
  const currentUser = useSelector(getUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuth, checkAuth, isDoneCheckAuth, authTokenClix } = useCheckAuth();



  const styleBgGradient = useMemo(() => {
    return {
      backgroundImage: `url(${skinInfo(currentUser.skin).gradient})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }
  }, [currentUser])

  const styleBgPic = {
    backgroundImage: 'url(/clicker/mainBg/pic.svg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }
  
  useEffect(() => {
    if (!isDoneCheckAuth) {
      checkAuth();
    }
  }, [isDoneCheckAuth])

  const navigateTo = '/auth' + window.location.search;

  useEffect(() => {
    if (!isAuth && isDoneCheckAuth) {
      navigate(navigateTo);
    }
  }, [isAuth, isDoneCheckAuth])

  const { data, call, isLoading } = useRequest(ClickerRepository.getUserInfo);
  const { data: dataSpace, call: callSpace, isLoading: isLoadingSpace } = useRequest(UserRepository.getUser);
  
  useEffect(() => {
    if (isAuth) {
      call();
      callSpace();
    }
  }, [isAuth])

  useEffect(() => {
    if (data?.statusCode === '403' || dataSpace?.statusCode === '403') {
      navigate(navigateTo);
    }
  }, [data, dataSpace])

  useEffect(() => {
    if (data && dataSpace) {
      dispatch(updateUser({...data, ...dataSpace}));
    }
  }, [data, dataSpace])

  return (
    <div style={styleBgPic} className="flex flex-col w-full min-h-screen h-full items-center justify-center overflow-auto"> 
     <div style={styleBgGradient} className="absolute top-0 left-0 w-full h-full" />
        <div style={styleBgGradient} className="relative flex flex-col items-center justify-between bg-[#050B15] h-full flex-1 space-y-5 max-w-[375px] sm:max-w-full max-h-[95vh] sm:max-h-[100vh] w-full rounded-[40px] sm:rounded-none app_shadow overflow-hidden border border-white-300 sm:border-none">
          {!isDoneCheckAuth ? (<div className="h-full text-white"></div>) : (
            <div className={`w-full h-full flex-1 flex flex-col overflow-auto ${parentStyle}`}>
              {children}
            </div>
          )}
          {/* <div className="flex flex-col items-center justify-center space-y-1.5 max-w-[85%] w-full my-auto">
            <span className="text-white text-3xl font-semibold">Engineering works</span>
            <span className="text-white-500">The site will be up and running soon</span>
          </div> */}
        </div>
    </div>
  )
}