import { requestApi } from "../api";
import { BaseRepository } from "./base";

class Repository extends BaseRepository {
  getClixLeaderboard = (params) => requestApi("get", `${this.path}/clix`, params);
  getNftLeaderboard = (params) => requestApi("get", `${this.path}/nft`, params);
  getGrandLeaderboard = (params) => requestApi("get", `${this.path}/grand`, params);
}

export const LeaderboardRepository = new Repository("/clicker/leaderboard");
