
import React, { useEffect } from "react";
import { useRequest } from "../../../../helpers/hooks/useRequest";
import { PartnersRepository } from "../../../../connectors/repositories/partners";

export const YourTeam = () => {

    const { data, isLoading, call } = useRequest(PartnersRepository.getUserPartners, [{ page:1, countItemsPerPage: 1000 }])

    useEffect(() => {
        call();
    }, [])

    return (
        <div className="flex flex-col px-4 space-y-[18px] z-[1] pb-4 ">
            <span className="text-xl text-white leading-none">Your Team</span>
            {!!data?.items?.length && (
                <div className="flex flex-col space-y-3.5 bg-white-100 backdrop-blur-small w-full h-full rounded-[20px] py-2.5 pl-2.5 pr-5">
                    {data?.items?.map((item, itemIndex) => {
                        const avatarBg = {
                            backgroundImage: `url(${item?.avatar})`,
                            backgroundRepeat: 'round',
                            backgroundSize: 'cover',
                          };
                        return (
                            <div className="flex items-center justify-between" key={itemIndex}>
                                <div className="flex items-center space-x-2.5 ">
                                    <div className="h-[54px] w-[54px] rounded-2xl" style={avatarBg} />
                                    <div className="flex flex-col ">
                                        <span className="text-white-600 font-light">{item?.nickname}</span>  
                                    </div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <img className="w-4 h-4 " src="/clicker/boost/boosters/frgxPink.png" />
                                    <span className="text-white text-sm font-light">{item?.clix}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}