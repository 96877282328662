import { requestApi } from "../api";
import { BaseRepository } from "./base";

class Repository extends BaseRepository {
  userNft = (params) => requestApi("get", `${this.path}/my`, params);
  syncNft = (params) => requestApi("get", `${this.path}/sync`, params);
  openNft = (params) => requestApi("post", `${this.path}/open`, params);
  changeNft = (params) => requestApi("post", `${this.path}/changeSkin`, params);
}

export const NftRepository = new Repository("/nft");
