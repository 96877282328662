import React, { useState, useEffect } from "react";
import { Modal } from "../../Modal";
import { useWeb3React } from "@web3-react/core";
import { UserRepository } from "../../../connectors/repositories/user";
import { callNotification } from "../../../helpers/notification";
import { ImageUploader } from "../../ImageUploader";
import { useDispatch } from 'react-redux';
import { updateUser } from "../../../store/userSlice";
import { useRequest } from "../../../helpers/hooks/useRequest";


export const EditProfileModal = ({ currentUser, openedModal, handleCloseModal }) => {

  const { account } = useWeb3React();
  const dispatch = useDispatch();

  const [avatar, setAvatar] = useState(null);

  const { data: dataSpace, call: callSpace, isLoading: isLoadingSpace } = useRequest(UserRepository.getUser);
  
  const onSaveAvatar = async () => {
    await UserRepository.setAvatar({
      avatarImage: avatar,
    })
      .then((response) => {
        callNotification({ type: 'success', message: 'Settings saved' });
        callSpace().then((result) => {
          dispatch(updateUser({...currentUser, ...result}));
        })
      })
      .catch((e) => callNotification({ type: 'error', message: e?.response?.data?.message || 'Something went wrong' }));
  };

  // useEffect(() => {
  //   if (!currentUser) return;
  //   if (currentUser?.avatar) {
  //     setAvatar(currentUser?.avatar);
  //   }
  //   // setInputsValue({
  //   //   nickname: data?.nickname,
  //   // });
  // }, [currentUser]);

  const [isEditNickname, setIsEditNickname] = useState(false);
  const [newNickname, setNewNickname] = useState('');

  const onSaveSettings = async () => {
    if (newNickname === currentUser?.nickname || !newNickname) {
      setIsEditNickname(false)
    } else {
      if (newNickname) {
        await UserRepository.updateNickname({
          newNickname: newNickname,
          newBio: currentUser?.bio || ' '
        })
          .then(() => {
            callNotification({ type: 'success', message: 'Settings saved' });
            setIsEditNickname(false);
            callSpace().then((result) => {
              dispatch(updateUser({...currentUser, ...result}));
            })
          })
          .catch((e) => callNotification({ type: 'error', message: e?.response?.data?.message || 'Something went wrong' }));
      }
    }
  };
  
  return (
    <Modal isOpened={openedModal} onClose={handleCloseModal}>
    <div className="flex flex-col items-center justify-between pt-10 pb-[30px] px-[10px] clan-modal w-[350px]  backdrop-blur-medium bg-white-100 border-[1px] border-white-100 rounded-[30px]  sm:w-[95%]">
      <div className="flex flex-col items-center space-y-8 w-full">
        <span className="text-2xl text-white">Edit Profile</span>
        <ImageUploader
                    className="cursor-pointer flex flex-col items-center justify-center space-y-4 w-[165px] h-[165px] bg-white-200 rounded-[20px]"
                    maxFileSize={2097152}
                    value={avatar}
                    onChange={(value) => setAvatar(value)}
                  >
                    
                      <button className="flex flex-col items-center justify-center w-full h-full bg-white-70 rounded-2xl space-y-2.5">
                        <img src="/icons/clanModal/picChange.svg"/>
                        <span className="text-center text-sm text-white-600 ">Upload <br />Your avatar</span>
                      </button>
                    
                  </ImageUploader>
              <button onClick={onSaveAvatar} className="flex items-center justify-center h-[50px] w-full backdrop-blur-[10px] bg-white-200 rounded-[20px] edit_profile_modal">
                  <span className="text-white">Save avatar</span>
              </button>
          
          <hr className="text-white w-full" />
        <div className="flex flex-col items-center justify-center space-y-2 w-full">
          <span className="text-white-600 text-sm font-light">Enter your nickname</span>
          {isEditNickname ? (
              <input placeholder='Your nickname' className="w-full text-center placeholder:text-white-300 text-white focus:outline-none h-[60px] rounded-[20px] bg-white-100" type='text' onChange={(e) => setNewNickname(e.target.value)} value={newNickname} />
          ) : (
             <button onClick={() => setIsEditNickname(true)} className="flex items-center space-x-[10px]">
              <span className="text-2xl text-white">{currentUser?.nickname}</span>
              <img src="/icons/clanModal/nameChange.svg"/>
            </button>
          )}
         
        </div>
        <div className="flex flex-col space-y-6 w-full">
            <button onClick={onSaveSettings} className="flex items-center justify-center h-[50px] w-full backdrop-blur-[10px] bg-white-200 rounded-[20px] edit_profile_modal">
                <span className="text-white">Save</span>
            </button>
        </div>
        {/* <hr className="text-white w-full" />
        <button onClick={account ? authAccount : () => {}} className="flex items-center justify-center h-[50px] w-full backdrop-blur-[10px] bg-white-200 rounded-[20px] edit_profile_modal">
                <span className="text-white">{account ? 'Set wallet' : 'Connect Wallet'}</span>
                <img className="pl-1" src="/icons/walletIcon.svg"/>
            </button> */}
      </div>
    </div>
  </Modal>
  );
};
