import React from "react";
import { getUser } from "../../../store/userSlice/selectors";
import { useSelector } from "react-redux";
import { ClaimButton } from "../../../components/ClaimButton";
import { ClaimNftButton } from "../../../components/ClaimNftButton";

export const ClaimWindow = () => {

    const currentUser = useSelector(getUser);

    return (
        <div className="flex flex-col items-center justify-center h-full flex-1 space-y-5">
            <img className="h-[75px]" src="/clicker/claimWindow/finish.webp" alt="" />
            <div className="flex space-x-2.5 items-center !mt-0">
                <img className="w-10 h-10" src="/clicker/profile/header/pinkFrgx.png"/>
                <span className="text-[44px] text-white font-semibold">{currentUser?.clicks}</span>
            </div>
            <div className="flex flex-col space-y-2.5 w-full">
                <ClaimButton closeClaim type="nftBalance" endTime="1715570000" />
                <ClaimButton closeClaim type="team" endTime="1716742800" />
                <ClaimButton closeClaim type="totalClixBalance" endTime="1717002000" />
                <ClaimNftButton endTime="1717002000" />
            </div>
        </div>
    );
}