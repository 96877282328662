import React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import clsx from 'clsx';

export const Modal = ({ isOpened, onClose, children, className, isDisableOnClose }) => {
  const preventDefaultCallBack = (callback) => (e) => {
    e.preventDefault();

    callback && callback(e);
  };

  return (
    <DialogOverlay
      className="header-dialog-overlay"
      isOpen={isOpened}
      as={'div'}
      onClick={!isDisableOnClose && preventDefaultCallBack(onClose)}
    >
      <DialogContent
        className={clsx(
          className,
          `header-dialog-content sm:m-0 sm:w-screen  sm:max-h-full sm:max-w-full relative`,
        )}
        as={'div'}
        aria-label={'modal'}
      >
        {children}
      </DialogContent>
     
    </DialogOverlay>
  );
};
